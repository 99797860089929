<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $tc('invitation', 2) | toCapitalize }}</h5>
    <hr>
    <b-table striped bordered hover :items="invitations" :fields="columns">
      <div slot="order" slot-scope="row" class="text-right">{{ row.index + 1 }}</div>
      <div slot="name" slot-scope="row">{{ row.item.name }}</div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" @click="show(row.item)"><i class="fa fa-eye"></i></b-button>
          <b-button variant="danger" class="mx-2" @click="destroy(row.item.id)"><i class="fa fa-trash px-1"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalDetail" :title="invitation.subject" centered>
      <h6>{{ invitation.message }}</h6>
      <div v-if="invitation.state === 'P'" class="text-center mt-5">
        <b-button @click="accept()" class="mx-3 px-5" variant="primary">{{ $t('accept') }}</b-button>
        <b-button @click="denied()" class="mx-3 px-5" variant="outline-primary">{{ $t('deny') }}</b-button>
      </div>
      <div v-if="invitation.state === 'A'" class="text-center mt-5">
        <h5>{{ $t('invitationAccepted') }}</h5>
      </div>
      <div v-if="invitation.state === 'D'" class="text-center mt-5">
        <h5>{{ $t('invitationRejected') }}</h5>
      </div>
      <hr>
      <span class="text-muted">{{ $t('date') }}: {{ invitation.created_at }}</span>
      <template slot="modal-footer">
        <b-button type="button" @click="$refs.modalDetail.hide()">{{ $t('close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      invitations: [],
      invitation: {},
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'sender', label: this.$t('from'), sortable: false },
        { key: 'subject', label: this.$t('subject'), sortable: false },
        { key: 'created_at', label: this.$t('date'), sortable: false },
        { key: 'actions', label: '', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team'
    ])
  },
  mounted () {
    if (this.team.name) {
      this.fetchData()
    }
  },
  methods: {
    destroy (invitationId) {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/invitations/${invitationId}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'))
        this.invitations = this.invitations.filter(not => {
          return not.id !== invitationId
        })
      })
    },
    accept () {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/invitations/${this.invitation.id}/accept`
      this.$axios.post(path).then(response => {
        this.changeStatus('A', response.data.message)
      })
    },
    changeStatus (state, message) {
      this.invitations = this.invitations.map(not => {
        if (not.id === this.invitation.id) {
          not.state = state
        }
        return not
      })
      this.$toastr.success(message)
      this.$refs.modalDetail.hide()
    },
    denied () {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/invitations/${this.invitation.id}/denied`
      this.$axios.post(path).then(response => {
        this.changeStatus('D', response.data.message)
      })
    },
    show (invitation) {
      this.invitation = invitation
      this.$refs.modalDetail.show()
      if (!invitation.viewed) {
        const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/invitations/${this.invitation.id}/show`
        this.$axios.post(path).then(() => {
          this.invitations = this.invitations.map(not => {
            if (not.id === this.invitation.id) {
              not.viewed = true
              not._rowVariant = ''
            }
            return not
          })
          this.$toastr.success(this.$t('updatedInfo'))
        })
      }
    },
    fetchData () {
      const teamId = this.$route.params.teamId
      const path = `auth/${this.lang}/${this.console}/teams/${teamId}/invitations`
      this.$axios.get(path).then(response => {
        this.invitations = response.data.data.map(not => {
          if (not.viewed === true) {
            not._rowVariant = ''
          } else {
            not._rowVariant = 'info'
          }
          return not
        })
      })
    }
  }
}
</script>
